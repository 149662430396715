import React, { useState } from 'react';
import '../styles/Home.css';
import Swal from 'sweetalert2';
import { SeparateAway } from './magicUIComponents/seperateAway';
import MailChimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe";

interface CustomFormProps {
  onValidated: (formData: EmailFormFields) => void;
  status: String | null,
  message: String | Error | null
}

const CustomForm: React.FC<CustomFormProps> = ({ onValidated, status, message }) => {
  const [email, setEmail] = useState<string>('');

  const handleEmailInput = async () => {
    const { value: email } = await Swal.fire({
      title: "Input email address",
      input: "text",
      inputLabel: "Your email address",
      showCloseButton: true,
      inputPlaceholder: "Enter your email address"
    });

    if (email) {
        if(status === 'success'){
            await Swal.fire({
                icon:"success",
                title: "Thanks for registering!",
                text:"You have successfully registered to our waitlist for the release of the mobile app",
                showCloseButton:true
            })
        }
        else if (status === "error"){
            await Swal.fire({
                icon:"error",
                title: "Oops!",
                text:"Sorry for the inconvenience, it is not on you. We are working towards making your experience better.",
                showCloseButton:true
            })
        }

        console.log("Email entered:",email)
      setEmail(email);

      onValidated({ EMAIL: email });
      
    }
  };

  return (
    <button onClick={handleEmailInput} className='button'>
      Get Started
    </button>
  );
};

const Home: React.FC = () => {
  const mailChimpUrl = process.env.REACT_APP_URL || '';
  return (
    <div className="container">
      <button className="top-button">
        <span role="img" aria-label="celebration">🎉</span>
        launching soon
        <span>→</span>
      </button>
      <div className='flex justify-center items-center text-center gap-3'>
        <SeparateAway
          upper_text='Setting Targets Has Never'
          lower_text=''
          className='heading'
          duration={2}
          hidden_opacity={0}
          visible_opacity={1}
        />
        <SeparateAway
          upper_text=''
          lower_text='Been This Easy'
          className='heading02'
          duration={2}
          hidden_opacity={0}
          visible_opacity={1}
        />
      </div>
      <MailChimpSubscribe
        url={mailChimpUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm onValidated={(formData: EmailFormFields) => subscribe(formData)}  status={status} message={message}/>
        )}
      />
    </div>
  );
};

export default Home;
